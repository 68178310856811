import React from 'react';
import map from '../images/map2.png'; // Replace with your image URL
import flags from '../images/flags.png'; // Replace with your image URL
import map3 from '../images/map3.png'; // Replace with your image URL


const CenteredImage = () => {
  // Styles for the container
  const containerStyle = {
    display: 'flex',           // Enables Flexbox
    justifyContent: 'center',  // Centers horizontally
    alignItems: 'center',      // Centers vertically
    height: '40vh',           // Full height of the viewport

  };

  return (
    <>
      <section style={{backgroundColor: '#020F26'}} className='pb-5 d-none d-md-block' id='map'>
          <div className='ms-5  pt-5'>
              <p className='fs-6 mb-0' style={{color: '#81CEFF'}} >Lo más rápidos</p>
              <h1 className='beba-font text-white' style={{fontSize: '70px'}}>Tiempos de transitos</h1>
          </div>
          <div style={containerStyle} className='ms-5'>
              <img src={map} alt="Centered" style={{width: '60%'}} />
          </div>
          <div className='d-none d-md-flex justify-content-center' style={{ marginRight: '40em'}}>
              <img src={flags} alt="Centered" width={200} />
          </div>
      </section>
      <section style={{backgroundColor: '#020F26'}} className='pb-5 d-block d-md-none'>
          <div className='ms-5  pt-5'>
              <p className='fs-6 mb-0' style={{color: '#81CEFF'}} >Lo más rápidos</p>
              <h1 className='beba-font text-white' style={{fontSize: '3em'}}>Tiempos de transitos</h1>
          </div>
          <div style={containerStyle} className='p-4'>
              <img src={map3} alt="Centered" style={{width: '100%'}} />
          </div>
          <div className='justify-content-center'>
              <ul  className='text-white list-unstyled mx-5 ps-2'>
              <li className="d-flex align-items-center">
                  <div className="d-inline-flex" style={{height: '16px'}}>
                    <div style={{
                      boxSizing: 'border-box',
                      width: '43px',
                      height: '16px',
                      background: 'rgba(203, 41, 67, 0.8)',
                      border: '1px solid #D10525',
                      borderRadius: '6px',
                    }}>
                    </div>
                  </div>
                  <span className="ms-2 text-nowrap">14 a 18 dias</span>
                </li>
                <hr className='text-white w-100 mx-auto' />
                <li className="d-flex align-items-center">
                  <div className="d-inline-flex" style={{height: '16px'}}>
                    <div style={{
                      boxSizing: 'border-box',
                      width: '43px',
                      height: '16px',
                      background: 'rgba(203, 41, 67, 0.6)',
                      border: '1px solid #D10525',
                      borderRadius: '6px',
                    }}>
                    </div>
                  </div>
                  <span className="ms-2 text-nowrap">12 a 14 dias </span>
                </li>
                <hr className=' w-100 mx-auto' />
                <li className="d-flex align-items-center">
                  <div className="d-inline-flex" style={{height: '16px'}}>
                    <div style={{
                      boxSizing: 'border-box',
                      width: '43px',
                      height: '16px',
                      background: 'rgba(255, 93, 118, 0.4)',
                      border: '1px solid #D10525',
                      borderRadius: '6px',
                    }}>
                    </div>
                  </div>
                  <span className="ms-2 text-nowrap">10 a 12 dias </span>
                </li>
                <hr className=' w-100 mx-auto' />
                <li className="d-flex align-items-center">
                  <div className="d-inline-flex" style={{height: '16px'}}>
                    <div style={{
                      boxSizing: 'border-box',
                      width: '43px',
                      height: '16px',
                      background: 'rgba(129, 206, 255, 0.8)',
                      border: '1px solid #0542C7',
                      borderRadius: '6px',
                    }}>
                    </div>
                  </div>
                  <span className="ms-2 text-nowrap">8 a 10 dias </span>
                </li>
                <hr className=' w-100 mx-auto' />
                <li className="d-flex align-items-center">
                  <div className="d-inline-flex" style={{height: '16px'}}>
                    <div style={{
                      boxSizing: 'border-box',
                      width: '43px',
                      height: '16px',
                      background: ' rgba(5, 66, 199, 0.8)',
                      border: '1px solid #0542C7',
                      borderRadius: '6px',
                    }}>
                    </div>
                  </div>
                  <span className="ms-2 text-nowrap">6 a 8 dias </span>
                </li>
                <hr className=' w-100 mx-auto' />
              </ul>
          </div>

      </section>
    </>
  );
};

export default CenteredImage;

