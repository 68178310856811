import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form, Spinner } from 'react-bootstrap';
const QuoteForm = () => {
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const envioDesdeRef = useRef(null);
    const hastaRef = useRef(null);

    const [formData, setFormData] = useState({
        vehiculo: '',
        envioDesde: '',
        hasta: '',
        nombreApellido: '',
        email: '',
        telefono: '',
    });

    const [errors, setErrors] = useState({
        vehiculo: true,
        envioDesde: true,
        hasta: true,
        nombreApellido: true,
        email: true,
        telefono: true,
    });

    const [touched, setTouched] = useState({
        vehiculo: false,
        envioDesde: false,
        hasta: false,
        nombreApellido: false,
        email: false,
        telefono: false,
    });

    useEffect(() => {
        if (!window.google) return;

        initAutocomplete(envioDesdeRef.current, 'envioDesde');
        initAutocomplete(hastaRef.current, 'hasta');
    }, []);

    const initAutocomplete = (element, fieldName) => {
        if (!element) return;

        const autocomplete = new window.google.maps.places.Autocomplete(element, { types: ['geocode'] });
        autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();
            if (!place.geometry) {
                setErrors(prev => ({ ...prev, [fieldName]: true }));
                setTouched(prev => ({ ...prev, [fieldName]: true }));
            } else {
                setFormData(prev => ({ ...prev, [fieldName]: place.formatted_address }));
                setErrors(prev => ({ ...prev, [fieldName]: false }));
                setTouched(prev => ({ ...prev, [fieldName]: true }));
            }
        });
    };

    const validateField = (name, value) => {
        const phoneRegex = /^\(\d{3}\)-\d{3}-\d{4}-?\d{0,4}$/;
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        switch (name) {
            case "vehiculo":
                return value.trim() !== "";
            case "nombreApellido":
                return value.trim() !== "";
            case "telefono":
                return phoneRegex.test(value);
            case "email":
                return emailRegex.test(value);
            default:
                return false;
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        let isValid = true;

        if (name === "telefono") {
            // Elimina todos los caracteres que no sean dígitos

            const digits = value.replace(/\D/g, "");

            // Limita la cantidad de dígitos a 15, permitiendo hasta 4 caracteres adicionales por los guiones
            const limitedDigits = digits.slice(0, 15);

            let formattedNumber = '';

            // Formatea el número con paréntesis y guiones
            if (limitedDigits.length <= 3) {
                formattedNumber = `(${limitedDigits}`;
            } else if (limitedDigits.length <= 6) {
                formattedNumber = `(${limitedDigits.slice(0, 3)})-${limitedDigits.slice(3)}`;
            } else if (limitedDigits.length <= 10) {
                formattedNumber = `(${limitedDigits.slice(0, 3)})-${limitedDigits.slice(3, 6)}-${limitedDigits.slice(6)}`;
            } else {
                formattedNumber = `(${limitedDigits.slice(0, 3)})-${limitedDigits.slice(3, 6)}-${limitedDigits.slice(6, 10)}-${limitedDigits.slice(10)}`;
            }
            // Limita la longitud total del campo a 16 caracteres (paréntesis, guiones y dígitos)
            formattedNumber = formattedNumber.substring(0, 19);

            isValid = validateField(name, formattedNumber);
            setFormData(prevData => ({ ...prevData, [name]: formattedNumber }));
        } else {
            isValid = validateField(name, value);
            setFormData(prevData => ({ ...prevData, [name]: value }));
        }

        setTouched(prev => ({ ...prev, [name]: true }));
        setErrors(prevErrors => ({ ...prevErrors, [name]: !isValid }));
    };

    const isFormValid = () => {
        return Object.values(errors).every(error => !error);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!isFormValid()) return; // Prevent submission if form is not valid
        setIsSubmitting(true); // Indicate form is being submitted

        try {
            await new Promise(resolve => setTimeout(resolve, 5000)); // Simulate async operation with a timeout
            const { vehiculo, envioDesde, hasta, nombreApellido, email, telefono } = formData;
            const options = {
                method: 'POST',
                // proxy: {
                //     protocol: 'http',
                //     host: '104.239.107.201',
                //     port: 5853
                // },
                headers: {
                    'Content-Type': 'application/json',
                    'cookie': 'qmb=0.', // Include if needed, otherwise remove
                },
                body: JSON.stringify({
                    fields: {
                        TITLE: nombreApellido,
                        EMAIL: [{ VALUE: email, VALUE_TYPE: 'WORK' }],
                        PHONE: [{ VALUE: telefono, VALUE_TYPE: 'WORK' }],
                        UF_CRM_1470686551: envioDesde,
                        UF_CRM_1470686597: hasta,
                        SOURCE_ID: "Solictud de cotización PuertoRicoCarTransfer",
                        UF_CRM_1486142337: vehiculo,
                        "ASSIGNED_BY_ID": "2", // Jose Garavito ID
                    }
                }),
            };

            const response = await fetch('https://colcargollc.bitrix24.com/rest/11056/6jv7knoe8e09jy8d/crm.lead.add.json', options);
            if (!response.ok) throw new Error('Network response was not ok');
            const data = await response.json();
            console.log("Lead added successfully!", data);
            navigate('/gracias')

        } catch (error) {
            console.error("Error submitting form:", error);

        } finally {
            // Reset form data, errors, and touched states
            setFormData({
                vehiculo: '',
                envioDesde: '',
                hasta: '',
                nombreApellido: '',
                email: '',
                telefono: '',
            });
            setErrors({
                vehiculo: true,
                envioDesde: true,
                hasta: true,
                nombreApellido: true,
                email: true,
                telefono: true,
            });

            setTouched({
                vehiculo: false,
                envioDesde: false,
                hasta: false,
                nombreApellido: false,
                email: false,
                telefono: false,
            });
            // Stop loading process
            setIsSubmitting(false); // Indicate form is being submitted

        }



    };

    return (
        <form onSubmit={handleSubmit} className="rounded-3" >
            <Form.Group controlId="formEnvioDesde" className='custom-form'>
                <Form.Label className='ms-2 text-white'>Envío desde</Form.Label>
                <Form.Control
                    type="text"
                    name="envioDesde"
                    ref={envioDesdeRef}
                    className="custom-input ps-4 mb-3"
                    isInvalid={errors.envioDesde && touched.envioDesde}
                    onFocus={() => setTouched({ ...touched, envioDesde: true })}
                />
                {errors.envioDesde && touched.envioDesde && <Form.Control.Feedback type="invalid">Por favor, seleccione un lugar válido.</Form.Control.Feedback>}
            </Form.Group>

            <Form.Group controlId="formHasta" className='custom-form mt-3'>
                <Form.Label className='ms-2 text-white'>Hasta</Form.Label>
                <Form.Control
                    type="text"
                    name="hasta"
                    ref={hastaRef}
                    className="custom-input ps-4 mb-3"
                    isInvalid={errors.hasta && touched.hasta}
                    onFocus={() => setTouched({ ...touched, hasta: true })}
                />
                {errors.hasta && touched.hasta && <Form.Control.Feedback type="invalid">Por favor, seleccione un lugar válido.</Form.Control.Feedback>}
            </Form.Group>

            <Form.Group controlId="formVehiculo" className='custom-form mt-3'>
                <Form.Label className='ms-2 text-white'>Vehículo</Form.Label>
                <Form.Control
                    type="text"
                    name="vehiculo"
                    placeholder='Año, modelo y marca del vehículo'
                    isInvalid={errors.vehiculo && touched.vehiculo}
                    onChange={handleChange}
                    className="custom-input ps-4 mb-3"
                />
                {errors.vehiculo && touched.vehiculo && <Form.Control.Feedback type="invalid">Por favor, introduzca un vehículo.</Form.Control.Feedback>}
            </Form.Group>

            <Form.Group controlId="formNombreApellido" className='custom-form mt-3'>
                <Form.Label className='ms-2 text-white'>Nombre y Apellido</Form.Label>
                <Form.Control
                    type="text"
                    name="nombreApellido"
                    placeholder='Nombre y apellido'
                    isInvalid={errors.nombreApellido && touched.nombreApellido}
                    onChange={handleChange}
                    onBlur={() => setTouched({ ...touched, nombreApellido: true })}
                    className="custom-input ps-4 mb-3"
                />
                {errors.nombreApellido && touched.nombreApellido && <Form.Control.Feedback type="invalid">Este campo es obligatorio.</Form.Control.Feedback>}
            </Form.Group>

            <Form.Group controlId="formEmail" className='custom-form mt-3'>
                <Form.Label className='ms-2 text-white'>Correo electrónico</Form.Label>
                <Form.Control
                    type="email"
                    name="email"
                    placeholder='Correo electrónico'
                    isInvalid={errors.email && touched.email}
                    onChange={handleChange}
                    onBlur={() => setTouched({ ...touched, email: true })}
                    className="custom-input ps-4 mb-3"
                />
                {errors.email && touched.email && <Form.Control.Feedback type="invalid">Por favor, introduzca un email válido.</Form.Control.Feedback>}
            </Form.Group>

            <Form.Group controlId="formTelefono" className='custom-form mt-3'>
                <Form.Label className='ms-2 text-white'>Número de teléfono</Form.Label>
                <Form.Control
                    type="tel"
                    name="telefono"
                    placeholder='Número de teléfono'
                    value={formData.telefono}
                    isInvalid={errors.telefono && touched.telefono}
                    onChange={handleChange}
                    onBlur={() => setTouched({ ...touched, telefono: true })}
                    className="custom-input ps-4 mb-3"
                />
                {errors.telefono && touched.telefono && <Form.Control.Feedback type="invalid">Por favor, introduzca un teléfono válido.</Form.Control.Feedback>}
            </Form.Group>

            <div className='d-flex justify-content-end mt-4'>
                <button className='btn btn-primary' type="submit" onClick={handleSubmit} disabled={!isFormValid() || isSubmitting}>
                    {isSubmitting ? (
                        <>
                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                            <span className="visually-hidden">Loading...</span>
                        </>
                    ) : (
                        "Solicitar cotización"
                    )}
                </button>
            </div>

        </form>

    );
};

export default QuoteForm;
