import React, {useEffect} from 'react';
import ThanksBG from '../images/form_bg.jpg'; // Adjust path as necessary

export default function About() {

  useEffect(() => {
    if (window.gtag) {
      window.gtag('event', 'conversion', {'send_to': 'AW-805159309/dL-KCPHn_ZgZEI2D9_8C'});
    }
  }, []);

  return (
    <React.Fragment>
      <>
        <section
              style={{
                backgroundImage: `url(${ThanksBG})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                paddingTop: '20rem',
                paddingBottom: '20rem',
                position: 'relative', // Make the section a relative container
                overflow: 'hidden', // Prevents any absolutely positioned child from overflowing
              }}
            >
          {/* Overlay */}
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)', // Dark transparent overlay
              zIndex: 1, // Sits above the background image
            }}
          />

          <div className='container'>
            <div className='row mb-5 text-white'     style={{
                position: 'relative', // This ensures content sits above the overlay
                zIndex: 2, // Higher zIndex than the overlay
                color: 'white'
              }}>
              <div className='col-12 col-lg-5 mx-auto text-center text-white section-content'>
                <h3 className='text-white'>
                  ¡Gracias por contactarnos!
                </h3>
                <h1 className='my-3 fs-1 fw-bold text-white'>
                  SOLICITUD RECIBIDA
                </h1>
                <p className='lead mb-0'>
                  Si deseas cotizar otro vehículo o saber mas de nosotros puedes volver a la pagina principal
                </p>
                <a className='btn btn-primary rounded my-5' href='/'>Volver al inicio</a>
              </div>
            </div>
          </div>
        </section>
      </>
    </React.Fragment>
  );
}

